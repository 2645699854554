import React, { useState, HTMLProps, ReactElement } from "react";

import {
  Light as SyntaxHighlighter,
  SyntaxHighlighterProps,
} from "react-syntax-highlighter";
import { stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import gcode from "react-syntax-highlighter/dist/esm/languages/hljs/gcode";

import { Icon, IconVariant } from "_/components/icon";

import * as S from "./styled";

// More languages can be added here, but should be kept to the minimum
// required in order to minimize the bundle size.
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("gcode", gcode);

export const Code = ({
  className,
  ...props
}: SyntaxHighlighterProps): ReactElement => {
  const match = /language-(\w+)/.exec(className || "");
  const code = props.children as string;

  const CustomPre = (props: HTMLProps<HTMLDivElement>) => {
    const { children, style, ...rest } = props;
    const [copyState, setCopyState] = useState<
      "notAttempted" | "copied" | "error"
    >("notAttempted");

    // TODO: Refactor this along with the UUID tooltip copy logic to be reusable.
    function copyToClipboard() {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setCopyState("copied");
          setTimeout(() => setCopyState("notAttempted"), 3000);
        })
        .catch(() => {
          setCopyState("error");
          console.error("Could not copy code to clipboard.");
        });
    }

    const iconVariant = {
      notAttempted: "ContentCopy",
      error: "Error",
      copied: "CheckCircle",
    }[copyState] as IconVariant;

    return (
      <div
        style={{ ...style, position: "relative", padding: "1em 0" }}
        {...rest}
      >
        <S.CopyCode onClick={copyToClipboard}>
          <Icon variant={iconVariant} />
        </S.CopyCode>
        {children}
      </div>
    );
  };

  return match ? (
    <SyntaxHighlighter
      language={match[1]}
      PreTag={CustomPre}
      showLineNumbers
      lineNumberStyle={{ color: "#ccc", minWidth: "3.25em" }}
      style={stackoverflowLight}
      {...props}
    />
  ) : (
    <code className={className} {...props} />
  );
};

import React, { HTMLProps, useState } from "react";
import { Icon, IconVariant } from "_/components/icon";

import * as S from "./styled";

/**
 * Component to show a code sample, with a button to copy the code to the clipboard.
 *
 * Note that this component is similar to components/docs-view/code.tsx and
 * components/uuid-tooltip/index.tsx, and at some point we should unify them.
 */
export const CopyableCode = (
  props: Omit<HTMLProps<HTMLDivElement>, "children"> & {
    children: string | undefined;
  }
) => {
  const { children, ...rest } = props;
  const [copyState, setCopyState] = useState<
    "notAttempted" | "copied" | "error"
  >("notAttempted");
  const code = props.children;
  if (!code) return null;

  function copyToClipboard(toCopy: string) {
    navigator.clipboard
      .writeText(toCopy)
      .then(() => {
        setCopyState("copied");
        setTimeout(() => setCopyState("notAttempted"), 3000);
      })
      .catch(() => {
        setCopyState("error");
        console.error("Could not copy code to clipboard.");
      });
  }

  const iconVariant = {
    notAttempted: "ContentCopy",
    error: "Error",
    copied: "CheckCircle",
  }[copyState] as IconVariant;

  return (
    <S.Container {...rest}>
      <S.ContentWrapper>{children}</S.ContentWrapper>
      <S.CopyButtonWrapper>
        <S.CopyButton onClick={() => copyToClipboard(code)}>
          <Icon variant={iconVariant} />
        </S.CopyButton>
      </S.CopyButtonWrapper>
    </S.Container>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 3rem;
  background-color: ${(p) => p.theme.background.secondary};
  border-radius: 4px;
`;

export const ContentWrapper = styled.div`
  font-family: monospace;
  padding: 1rem;
  flex-grow: 1;
`;

export const CopyButtonWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const CopyButton = styled.div`
  top: 0;
  right: 0;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.background.tertiary};
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.background.quaternary};
  }
`;
